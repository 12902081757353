'use strict';

var jQuery = window.jQuery || null;
var google = window.google || null;

(function ($) {
	var USHIO = null;
	var cssua = window.cssua || null;

	USHIO = {
		state: {
			active: 'is-active',
			visible: 'is-visible'
		},
		variable: {
			pathname: location.pathname,
			click: cssua.ua.mobile ? 'tap' : 'click',
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		debug: function debug() {
			return this.getQuery()['state'] === 'debug';
		},
		localDecision: function localDecision() {
			var local = /(test\.ushio\.co\.jp|copre\.jp|localhost|192\.168\.0\.)/g;
			return local.test(location.hostname);
		},
		lang: function lang() {
			return location.pathname.split('/')[1];
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key2 = 0; _key2 < _len; _key2++) {
						args[_key2] = arguments[_key2];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}

					// Debug用VorlonJSのJSを全ページに作る
					// _this.createVorlonJS();

					_this.loadDelayScript('local');
				});
			} else {
				_this.loadDelayScript('server');
			}
		},
		location: function location(_regexp) {
			return _regexp.test(this.variable.pathname);
		},
		createVorlonJS: function createVorlonJS() {
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = '//' + location.hostname + ':1337/vorlon.js';
			var s = document.getElementsByTagName('script')[0];

			return s.parentNode.insertBefore(script, s.nextSibling);
		},
		multipleAjaxProcess: function multipleAjaxProcess(_array) {
			var _dataType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';

			var ajaxArrayList = [];
			var arrayLen = _array.length;

			if (arrayLen > 0) {
				for (var i = 0; i < arrayLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: _array[i],
						dataType: _dataType,
						timeout: 5000
					});
				}
				return ajaxArrayList;
			}
		},

		adjustContentsHeight: {
			init: function init() {
				var _this2 = this;

				this.fn();
				$(window).on('load', function () {
					return _this2.fn();
				});
			},
			fn: function fn() {
				$('.nav-wrap').removeAttr('style');
				$('.contents').removeAttr('style');

				var $navHeight = $('.nav-wrap').outerHeight(true);
				var $contentsHeight = $('.contents').outerHeight(true);

				if ($navHeight > $contentsHeight) {
					$('.contents').height($navHeight);
				}
				if ($navHeight < $contentsHeight) {
					$('.nav-wrap').height($contentsHeight);
				}
			}
		},
		setContainerHeightToNav: function setContainerHeightToNav() {
			return $('.nav').height($('.container').outerHeight(true));
		},
		adjustScrollNavigation: function adjustScrollNavigation() {
			var elNavWrap = document.querySelector('.nav-wrap');
			var $footer = $('.footer');

			if (elNavWrap !== null && $footer.length > 0) {
				var $this = $(window),
				    thisScroll = $this.scrollTop(),
				    pagetopPos = $this.height() + $footer.height() + thisScroll,
				    footerPos = $footer.offset().top + $footer.height(),
				    macWhitespace = 500,
				    winWhitespace = 400,
				    ieWhitespace = 450,
				    footerSpace = 0;

				if (cssua.ua.mac_os_x) footerSpace = macWhitespace;else if (cssua.ua.ie) footerSpace = ieWhitespace;else footerSpace = winWhitespace;

				var footerDiff = pagetopPos - footerPos + footerSpace;

				if (footerDiff > 0) {
					elNavWrap.style.top = '-' + footerDiff + 'px';
				} else {
					elNavWrap.style.top = 0;
				}
				if (thisScroll === 0) elNavWrap.style.top = 0;
			}
		},
		heightAdjustmentAfterDOMAddition: function heightAdjustmentAfterDOMAddition(_namespace) {
			var loadNavHeight = $('.header > .nav').height();
			var navFlags = false;
			$(window).on('scroll.' + _namespace, function () {
				if (navFlags) $(window).off('scroll.' + _namespace);

				if (!navFlags && $('.container').outerHeight(true) > loadNavHeight) {
					USHIO.adjustContentsHeight.fn();
					USHIO.setContainerHeightToNav();
					navFlags = true;
				}
			});
		},
		areaOuterClickInClose: function areaOuterClickInClose(_eventTarget, _childTarget) {
			var _this = this;
			var visible = _this.state.visible;

			$('html').on('click', function (ev) {
				var isTarget = $(ev.target).closest(_childTarget).length;

				if (isTarget === 0 && $(_eventTarget).hasClass(visible)) {
					$(_eventTarget).removeClass(visible);
					$(_childTarget).removeClass(visible);
				}
			});
		},
		iconClickAnimation: function iconClickAnimation() {
			var time = 10;
			var animateSpeed = 350;

			$('body').on(this.variable.click, '.js-icon', function (event) {
				var $this = $(event.currentTarget);
				var attr = $this.attr('data-with');
				$('.js-icon[data-with="' + attr + '"]').removeClass('is-checked is-animation');

				$this.hasClass('is-checked') ? toggleClassControl($this, 'remove') : toggleClassControl($this, 'add');
			});
			$('body').on(this.variable.click, '.js-icon2', function (event) {
				var $this = $(event.currentTarget);

				$this.removeClass('is-animation').addClass('is-checked');
				setTimeout(function () {
					$this.addClass('is-animation');
				}, time);
				setTimeout(function () {
					$this.removeClass('is-checked');
				}, animateSpeed);
			});

			function toggleClassControl(_self, _state) {
				if (_state === 'add') _self.removeClass('is-animation').addClass('is-checked');
				if (_state === 'remove') _self.removeClass('is-animation').removeClass('is-checked');
				setTimeout(function () {
					_self.addClass('is-animation');
				}, time);
			}
		},
		setStyleMobileDevice: function setStyleMobileDevice() {
			var link = document.createElement('link');
			link.type = 'text/css';
			link.rel = 'stylesheet';
			link.href = '/common/css/mobile.css';
			var s = document.getElementsByTagName('link')[0];

			return s.parentNode.insertBefore(link, s.nextSibling);
		},
		bytesToSize: function bytesToSize() {
			var $obj = $('.js-bytesToSize');
			var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

			if ($obj.length > 0) {
				var bit = 1024;
				for (var i = 0; i < $obj.length; i++) {
					var objText = $obj.eq(i).text();
					if (objText === 0) return 'n/a';
					var num = parseInt(Math.floor(Math.log(objText) / Math.log(bit)), 10);
					if (num === 0) return objText + ' ' + sizes[num];

					$obj.eq(i).text((objText / Math.pow(bit, num)).toFixed(1) + ' ' + sizes[num]);
				}
			}
		},
		changeFilterItem: function changeFilterItem() {
			var _this = this;
			var $filterTab = $('.js-filterTab');
			var $filterList = $('.js-filterList');
			// var active = _this.state.active;
			var visible = _this.state.visible;
			var allVisibleClass, queryTab, hash, $filterListLi, $filterTabLi, category, filterListliLen, i, target, keyArray;

			if ($filterTab.length > 0 && $filterList.length > 0) {
				allVisibleClass = 'all';
				queryTab = _this.getQuery()['tab'];

				if (queryTab && queryTab.length && queryTab.indexOf('#') > 0) {
					var querySplit = queryTab.split('#');
					queryTab = querySplit[0];
					hash = '#' + querySplit[1];
				}

				$filterListLi = $filterList.find('[data-key]');
				$filterTabLi = $filterTab.find('[data-key]');
				category = $filterTab.find('[data-key]:first-child').attr('data-key');
				filterListliLen = $filterListLi.length;
				keyArray = [];

				if ($filterTabLi.length > 0) {
					// tabのdata-keyを配列
					for (i = 0; i < $filterTabLi.length; i++) {
						target = $filterTabLi.eq(i).attr('data-key') || $filterTabLi.eq(i).find('[data-key]').attr('data-key');

						if ($.inArray(target, keyArray) === -1) {
							keyArray.push(target);
						}
					}
					init();
				} else {
					$filterTab.remove();
				}
			}

			function init() {
				if (queryTab && $.inArray(queryTab, keyArray) !== -1) {
					if (history.replaceState && hash) {
						history.replaceState(queryTab, null, '?tab=' + (queryTab + hash));
					} else if (history.replaceState) {
						history.replaceState(queryTab, null, '?tab=' + queryTab);
					}
					changeItem(queryTab);
					findListTransform();
				} else {
					if (history.replaceState) {
						history.replaceState(category, null, null);
					}
					changeItem(category);

					if (keyArray.indexOf(allVisibleClass) >= 0) {
						$filterListLi.addClass(visible);
					} else {
						for (i = 0; i < filterListliLen; i++) {
							target = $filterListLi.eq(i);
							var key = target.attr('data-key');
							var array = key.split(',');

							if (key === category || array.indexOf(category) !== -1) {
								target.addClass(visible);
							} else {
								target.hide();
							}
						}
					}
					findListTransform();

					$(window).on('load', function () {
						USHIO.adjustContentsHeight.fn();
						USHIO.setContainerHeightToNav();
						USHIO.adjustScrollNavigation();
					});
				}
				tabItemDisplayCheck();
				viewControl();
			}
			function changeItem(_cat) {
				$filterTabLi = $filterTab.find('[data-key]');
				$filterListLi = $filterList.find('[data-key]');
				filterListliLen = $filterListLi.length;

				$filterTabLi.removeClass(visible);
				$filterListLi.removeClass(visible).hide();
				$filterTab.find('[data-key="' + _cat + '"]').addClass(visible);
				$filterList.find('[data-key*="' + _cat + '"]').addClass(visible);

				if (_cat === allVisibleClass) {
					$filterListLi.addClass(visible).show();
				} else {
					for (i = 0; i < filterListliLen; i++) {
						var $obj = $filterListLi.eq(i);
						var array = $obj.attr('data-key').split(',');

						if (array.indexOf(_cat) !== -1) {
							var tagName = $obj.tagName;

							if (cssua.ua.firefox) {
								$obj.get(0).style.display = 'inline-block';
							} else if (tagName === 'LI') {
								$obj.get(0).style.display = 'list-item';
							} else if ($obj.hasClass('prdDtl-detail')) {
								$obj.get(0).style.display = 'block';
							} else {
								$obj.get(0).style.display = 'inline-block';
							}
						}
					}

					// 製品詳細のアンカーリンク対応
					if (hash && $(hash).parent().hasClass('ent_ct_title')) {
						var delay = 250;

						setTimeout(function () {
							_this.toggleControl(hash, $(hash).parent().next('.ent_ct_body'));
						}, delay);
					}
				}

				USHIO.adjustContentsHeight.fn();
				USHIO.setContainerHeightToNav();
				USHIO.adjustScrollNavigation();
			}
			function viewControl() {
				$filterTabLi.on('click', function (event) {
					var $this = $(event.currentTarget);
					category = $this.attr('data-key');
					if (category !== 'link') {
						if (history.pushState) history.pushState(category, null, '?tab=' + category);
						changeItem(category);
						findListTransform();
						tabItemDisplayCheck();
						if ($(document).find('.ps-container').length > 0) {
							$('.ps-container').perfectScrollbar('update');
						}
					}
				});
				$(window).on('popstate.filterTab', function () {
					if (history.state) {
						changeItem(history.state);
						findListTransform();
					}
				});
			}
			function tabItemDisplayCheck() {
				var noItem = document.querySelector('.js-filterNoItem');

				if (noItem !== null) {
					var listLi = document.querySelectorAll('.js-filterList > li');
					var listLiLen = listLi.length;
					var falseCounter = 0;

					for (var j = 0; j < listLiLen; j++) {
						if (listLi[j].style.display === 'none') {
							falseCounter++;
						}
					}
					if (listLiLen === falseCounter) {
						$(noItem).addClass('is-visible');
					} else {
						$(noItem).removeClass('is-visible');
					}
				}
			}
			function findListTransform() {
				var t = document.querySelectorAll('.js-listTransform');
				var count = 3;
				var space = 20;

				if (t.length > 0) {
					_this.tech.startListTransform(count, space);
				}
			}
		},
		globalMenuHoverAction: function globalMenuHoverAction() {
			// 旧ヘッダー /kr/のみ
			var _this = this;
			var active = _this.state.active;
			var visible = _this.state.visible;
			var $displayItem;

			$('.js-menu_click').on('mouseover', function (event) {
				var $this = $(event.currentTarget);
				var item = $this.attr('data-item');

				changingClassInHover($this, '.js-menu_click', '.js-menu_display');

				// 製品情報にホバーしたらjson取得しにいく
				if (item && item === 'products') {
					_this.acquisitionProductInformation();
				}

				event.stopPropagation();
			});
			$('.js-menu_click2').on('mouseover', function (event) {
				var $this = $(event.currentTarget);
				var item = $this.attr('data-item');
				changingClassInHover($this, '.js-menu_click2', '.js-menu_display2');

				// 光技術用語解説にホバーしたら各行の合計数を取得しに行く
				if (item && item === 'technology-glossary' && $('.glossary_all_count').text().length === 0) {
					_this.glossaryCount();
				}

				event.stopPropagation();
			});
			$('.nav-menu-title').on('mouseover', function (e) {
				forciblyClose($(e.currentTarget), '.js-menu_click', '.js-menu_display[data-item]');
			});
			$('.menu-list-a').on('mouseover', function (e) {
				forciblyClose($(e.currentTarget), '.js-menu_click2', '.js-menu_display2[data-item]');
			});
			$('.menu').on('mouseleave', function () {
				$('.nav-menu-title').removeClass(active);
				$('.menu-list').removeClass(visible);
				$('.menu-list2').removeClass(visible);
			});

			$('html').on(this.variable.click, function (ev) {
				var $clickArea = $(ev.target);
				var $display1 = $clickArea.closest('.js-menu_display');
				var $display2 = $clickArea.closest('.js-menu_display2');
				var display1Length = $display1.length;
				var display2Length = $display2.length;

				if (display1Length === 0 && display2Length === 0) {
					htmlClickInMenuClose('.js-menu_click', '.js-menu_display');
					htmlClickInMenuClose('.js-menu_click2', '.js-menu_display2');

					$(window).off('resize.menuHover');
				}
			});

			// ホバーした際にdata-itemが無ければ全てのメニューを閉じる
			function forciblyClose(_self, _hover, _object) {
				var item = _self.attr('data-item');

				if (typeof item === 'undefined') {
					$(_hover).removeClass(active);
					$(_object).removeClass(visible);
				}
			}
			function changingClassInHover(_self, _hover, _object) {
				var $this = _self;
				var item = $this.attr('data-item');
				var resizeTimer;
				var timer = 200;
				$displayItem = $(_object + '[data-item="' + item + '"]');

				if (!$this.hasClass(active)) {
					$(_hover).removeClass(active);
					$(_object + '[data-item]').removeClass(visible);

					$this.addClass(active);
					$displayItem.addClass(visible);

					checkScrollbar($displayItem);
				}

				if (_object === '.js-menu_display' && $('.js-menu_display2').hasClass(visible)) {
					$('.js-menu_click2').removeClass(active);
					$('.js-menu_display2').removeClass(visible);
				}

				$(window).on('resize.menuHover', function () {
					if (resizeTimer !== false) clearTimeout(resizeTimer);
					resizeTimer = setTimeout(function () {
						checkScrollbar();
					}, timer);
				});
			}
			function checkScrollbar() {
				var winH = $(window).height();
				var $item = $('.menu-list.is-visible');
				var $item2 = $('.menu-list2.is-visible');
				var $item3 = $('.menu-list3.is-visible');

				if ($item.height() > winH) addScrollbar($item);
				if ($item2.height() > winH) addScrollbar($item2);
				if ($item3.height() > winH) addScrollbar($item3);

				if ($item.attr('style') && $item.height() < winH) removeStyle($item);
				if ($item2.attr('style') && $item2.height() < winH) removeStyle($item2);
				if ($item3.attr('style') && $item3.height() < winH) removeStyle($item3);

				function addScrollbar(_item) {
					return _item.css({ 'height': winH + 'px' }).perfectScrollbar();
				}
				function removeStyle(_item) {
					return _item.attr('style', 'height: auto').perfectScrollbar('destroy');
				}
			}
			function htmlClickInMenuClose(_hover, _object) {
				if ($(_object).hasClass(visible)) {
					$(_hover).removeClass(active);
					$(_object).removeClass(visible);
				}
			}
		},
		headerMenu: function headerMenu() {
			// 新ヘッダー
			$('.header-nav-main').find('a').each(function (idx, ele) {
				var target = $(ele).attr('data-target');

				$(ele).on('mouseenter', function () {
					$('.header-menu').addClass('is-active');

					$('.header-menu-content').each(function (idx2, ele2) {
						$(ele2).removeClass('is-visible').removeClass('is-active');
					});
					$('.header-menu-content[data-item="' + target + '"]').addClass('is-active');
					setTimeout(function () {
						$('.header-menu-content[data-item="' + target + '"]').addClass('is-visible');
					}, 300);
					$('.header-menu-content[data-item="' + target + '"]').find('a').matchHeight().addClass('is-match');
				});
			});
			$('.header').on('mouseleave', function () {
				$('.header-menu').removeClass('is-active');
			});
		},
		glossaryCount: function glossaryCount() {
			var jsonValue = null;
			var countArr = [{ 'あ': 0, 'scope': 'あ-お', 'alphabet': 'a' }, { 'か': 0, 'scope': 'か-こ', 'alphabet': 'ka' }, { 'さ': 0, 'scope': 'さ-そ', 'alphabet': 'sa' }, { 'た': 0, 'scope': 'た-と', 'alphabet': 'ta' }, { 'な': 0, 'scope': 'な-の', 'alphabet': 'na' }, { 'は': 0, 'scope': 'は-ほ', 'alphabet': 'ha' }, { 'ま': 0, 'scope': 'ま-も', 'alphabet': 'ma' }, { 'や': 0, 'scope': 'や-よ', 'alphabet': 'ya' }, { 'ら': 0, 'scope': 'ら-ろ', 'alphabet': 'ra' }, { 'わ': 0, 'scope': 'わ-ん', 'alphabet': 'wa' }];
			var countArrAll = 0;

			$.ajax({
				url: '/' + USHIO.lang() + '/ajax/leftmenu/glossary_count',
				type: 'GET',
				dataType: 'jsonp',
				timeout: 10000
			}).done(function (json) {
				var obj = json['glossary_all'];
				if (obj.length > 0) jsonValue = obj;
			}).fail(function () {
				$('[data-item="technology-glossary"]').find('.menu-list2-cnt').remove();
			}).always(function () {
				var i, j, item, obj;
				var jsonLength = jsonValue.length;
				var countLength = countArr.length;

				for (i = 0; i < jsonLength; i++) {
					var json = jsonValue[i];
					var initial = json['ent_g_initial'];
					var count = json['count'];

					for (j = 0; j < countLength; j++) {
						obj = countArr[j];

						for (item in obj) {
							if (obj.hasOwnProperty(item)) {
								if (item === 'scope' || item === 'alphabet') {
									continue;
								}
								var reg = new RegExp('[' + obj.scope + ']', 'i');

								if (initial.match(reg)) {
									obj[item] += count;
								}
							}
						}
					}
					countArrAll += count;
				}

				for (i = 0; i < countLength; i++) {
					obj = countArr[i];

					for (item in obj) {
						if (obj.hasOwnProperty(item)) {
							if (item === 'scope' || item === 'alphabet') {
								continue;
							}
							$('.glossary_' + obj.alphabet + '_count').text(obj[item]);
						}
					}
				}
				$('.glossary_all_count').text(countArrAll);
			});
		},
		acquisitionProductInformation: function acquisitionProductInformation() {
			var menuLinkLength = document.querySelectorAll('.menu-list2-field-li').length;
			var menuLinkFlagCount = 1;
			var timeout = 20000;

			if (menuLinkLength < menuLinkFlagCount) {
				var minHeight = $('.js-menu_display[data-item="products"]').outerHeight(true);

				// 分野・業界から探す
				$.ajax({
					url: '/' + USHIO.lang() + '/ajax/menu/applications/genres',
					type: 'GET',
					dataType: 'jsonp',
					timeout: timeout
				}).done(function (json) {
					var data = json['products_applications'];
					var html = '';

					for (var i = 0, dataLength = data.length; i < dataLength; i++) {
						var item = data[i];
						var obj = {
							key: item['_key'],
							name: item['cms_at_name'],
							image: {
								alt: item['cms_at_image_file']['alt'],
								src: item['cms_at_image_file']['src']
							},
							lang: {
								ja: item['ent_at_name_ja']
							},
							status: item['ent_at_status']
						};
						if (item['cms_at_image_file']['alt'] === null) obj.image.alt = '';
						if (item['cms_at_image_file']['src'] === null) {
							obj.image.src = '/common/images/noimage_300x300.png';
						}

						var cname = encodeURI(item['cms_at_name']);
						html += '<li class="menu-list2-field-li pure-u-1-6">\n\t\t\t\t\t\t\t<a href="/' + USHIO.lang() + '/products/keyword_details.html?genre_tag=' + obj.key + '&key_label=' + cname + '">\n\t\t\t\t\t\t<img class="width-u-100" src="' + obj.image.src + '" alt="' + obj.image.alt + '" title="' + obj.image.alt + '">\n\t\t\t\t\t\t<span class="menu-list2-field-name">' + obj.name + '</span>\n\t\t\t\t\t\t</a>\n\t\t\t\t\t\t</li>';
					}

					$('.js-menu_display2[data-item="products-fieldIndustry"]').find('.menu-list2-field').append(html).css({
						'min-height': minHeight + 'px'
					});
				});
			}
		},
		footerBannerScrollingFollowUp: function footerBannerScrollingFollowUp() {
			var $up = $('.js-followUp');
			if ($up.length > 0) {
				$up.on('mouseenter', function (event) {
					var $this = $(event.currentTarget);
					$this.addClass('is-mouseenter');
				});
				$up.on('mouseleave', function (event) {
					var $this = $(event.currentTarget);
					$this.removeClass('is-mouseenter');
				});

				$(window).on('scroll', function (event) {
					var $this = $(event.currentTarget),
					    thisScroll = $this.scrollTop(),
					    footer = $('.footer'),
					    pagetopPos = $this.height() + $(footer).height() + thisScroll,
					    footerPos = $(footer).offset().top + $(footer).height();

					if (thisScroll === 0) {
						$up.removeClass('is-moved is-fixed').addClass('is-reset');
					} else {
						if (thisScroll > 0 && footerPos > pagetopPos) {
							$up.removeClass('is-reset is-fixed').addClass('is-moved');
						}
						if (footerPos <= pagetopPos) {
							$up.removeClass('is-reset is-moved').addClass('is-fixed');
						}
					}
				});
			}
		},
		modalWindow: function modalWindow() {
			var overlay = 'mod-overlay',
			    modal = 'mod-modal',
			    visible = this.state.visible,
			    modalH,
			    winH,
			    scrollH,
			    modalTarget,
			    slideTarget,
			    modalLen,
			    slideLen,
			    slideGroup = [],
			    addDelay = 300,
			    i,
			    j;

			init();

			function init() {
				modalTarget = document.querySelectorAll('.js-modalTarget[data-modal]');
				slideTarget = document.querySelectorAll('.js-modalTarget[data-modal][data-slide]');
				modalLen = modalTarget.length;
				slideLen = slideTarget.length;

				if (modalLen > 0) {
					for (i = 0; i < modalLen; i++) {
						modalTarget[i].setAttribute('data-num', i);
					}
				}
				if (slideLen > 0) {
					var slide, slideAttr;

					for (i = 0; i < slideLen; i++) {
						slide = slideTarget[i];
						slideAttr = slide.getAttribute('data-slide');

						if (slideGroup.indexOf(slideAttr) === -1) {
							slideGroup.push(slideAttr);
						}
					}
					for (i = 0; i < slideGroup.length; i++) {
						slide = document.querySelectorAll('.js-modalTarget[data-modal][data-slide="' + slideGroup[i] + '"]');

						for (j = 0; j < slide.length; j++) {
							slide[j].setAttribute('data-slideCount', j);
						}
					}
				}

				if (modalLen) viewControl();
			}
			function viewControl() {
				var body = document.body;
				var findOverlay = body.querySelector('.' + overlay);
				var $target, modalName, count, html, slide, resizeTimer;
				var revise = 0.05;
				var minTimer = 100;
				var timer = $(document).outerHeight(true) * revise;
				timer = timer < minTimer ? minTimer : timer;

				var getParameter = function getParameter(_property) {
					slide = 0;
					scrollH = $(window).scrollTop();
					winH = $(window).height();

					$target = $('.js-modalTarget[data-modal="' + _property + '"]');
					modalName = $target.attr('data-modal');
					count = $target.attr('data-slideCount');
					html = $target.html();

					if (typeof html === 'undefined') return false;

					if (typeof $target.attr('data-slide') !== 'undefined') {
						slide = $target.attr('data-slide');
					}
					$(body).prepend('<div class="' + overlay + '"></div>');

					addModal(modalName, count, html, addDelay, slide);
				};

				if (typeof USHIO.getQuery()['modal'] !== 'undefined') {
					var query = USHIO.getQuery()['modal'];
					var ieTimer = 500;

					if (cssua.ua.ie) {
						// IE10でwindow.onLoadイベントが発火しない…ので苦渋のsetTimeout
						setTimeout(function () {
							getParameter(query);
						}, ieTimer);
					} else {
						$(window).on('load', function () {
							getParameter(query);
						});
					}
				}
				$('.js-modal[data-modal]').on('click', function (ev) {
					ev.preventDefault();

					if (findOverlay === null) {
						modalName = $(ev.currentTarget).attr('data-modal');
						getParameter(modalName);
					}
				});

				$('body').on('click', '.' + modal + '-slide-right', function () {
					var group = $(this).parent().attr('data-slide');
					transitionModal(this, 'right', group);
				});
				$('body').on('click', '.' + modal + '-slide-left', function () {
					var group = $(this).parent().attr('data-slide');
					transitionModal(this, 'left', group);
				});
				$('body').on('click', '.' + modal + '-close, .' + overlay, function () {
					removeModal(true);
				});
				$(window).on({
					'load': function load() {
						timer = $(document).outerHeight(true) * revise;
						timer = timer < minTimer ? minTimer : timer;
					},
					'resize': function resize() {
						if (resizeTimer !== false) clearTimeout(resizeTimer);
						resizeTimer = setTimeout(function () {
							heightCalcIconAdd();
						}, timer);
					},
					'popstate': function popstate() {
						if (history.state) {
							var $stateTarget = $('.js-modalTarget[data-modal="' + history.state + '"]');
							var itemAttr = 0;
							var modalLength = $('.mod-modal').length;

							if (typeof $stateTarget.attr('data-slide') !== 'undefined') {
								itemAttr = $stateTarget.attr('data-slide');
							}
							if (modalLength === 0) {
								modalName = $stateTarget.attr('data-modal');
								count = $stateTarget.attr('data-slideCount');
								html = $stateTarget.html();

								$(body).prepend('<div class="' + overlay + '"></div>');
								addModal(modalName, count, html, addDelay, itemAttr);
							} else {
								transitionModal(history.state, 'popstate', itemAttr);
							}
						}
					}
				});
			}
			function addModal(_name, _num, _html, _delay, _slide) {
				var timer;
				var $overlay = $('.' + overlay);
				$overlay.after('<div class="' + modal + '" data-modal="' + _name + '" data-num="' + _num + '">' + _html + '</div>');
				var $modal = $('.' + modal);

				if (timer) clearTimeout(timer);
				if (_slide.length > 0) {
					$modal.attr({
						'data-slideCount': _num,
						'data-slide': _slide
					});
				}

				makeInnerSlide();
				heightCalcIconAdd(_slide, Number(_num), $('.js-modalTarget[data-slide="' + _slide + '"]'));
				$overlay.addClass(visible);

				// print対応class
				$(document.body).addClass('is-modal');

				timer = setTimeout(function () {
					$modal.addClass(visible);

					changeHistory(_name);
				}, _delay);
			}
			function transitionModal(_self, _direction, _group) {
				var $parent,
				    $nextTarget,
				    $targetGroup,
				    num,
				    maxLen,
				    html,
				    addModalName,
				    slide = 0,
				    dir = _direction;

				if (dir === 'popstate') {
					$parent = $('.' + modal);
					$targetGroup = $('.js-modalTarget[data-modal="' + _self + '"]');
					num = $targetGroup.attr('data-slideCount');
					$nextTarget = $targetGroup;
					addModalName = _self;
					$parent.attr('data-modal', addModalName);
				} else {
					$parent = $(_self).parent();
					$targetGroup = $('.js-modalTarget[data-slide="' + _group + '"]');
					num = Number($parent.attr('data-slideCount'));
					maxLen = $targetGroup.length;

					// console.log('num', num);
					// console.log('$nextTarget.length', $nextTarget.length);

					if (dir === 'right') {
						num++;
						// if(num === ($nextTarget.length-1)) {
						// 	console.log('fire');
						// 	$('.mod-modal-slide-right.icon-angle-right').addClass('di_none');
						// 	return false;
						// } else {
						// 	num++;
						// }
					}
					if (dir === 'left') {
						--num;
						// if(num === 0) {
						// 	console.log('fire');
						// 	$('.mod-modal-slide-left.icon-angle-left').addClass('di_none');
						// 	return false;
						// } else {
						// 	--num;
						// }
					}

					if (num >= $targetGroup.length) {
						num = 0;
						// $(`.mod-modal-slide-${dir}.icon-angle-${dir}`).remove();
					}

					$nextTarget = $targetGroup.eq(num);
					addModalName = $nextTarget.attr('data-modal');
					$parent.attr('data-modal', addModalName);

					changeHistory(addModalName);
				}
				$nextTarget = $nextTarget || $nextTarget.prevObject[0];

				if (typeof $nextTarget.attr('data-slide') !== 'undefined') {
					slide = $nextTarget.attr('data-slide');
				}
				html = $nextTarget.html();

				changeHTML(addModalName, $parent, num, html, slide);
				makeInnerSlide();
				heightCalcIconAdd(slide, num, $targetGroup);

				// removeModal(false, function(){
				// 	addModal(num, html, addDelay, slide);
				// });
			}
			function heightCalcIconAdd(_slide, _num, _nextTarget) {
				var $modal = $('.' + modal);
				var $modalChildDiv = $modal.find('> div');
				var modalChildDivH = $modalChildDiv.outerHeight(true);
				winH = $(window).height();
				scrollH = $(window).scrollTop();
				// modalH = $modal.find('> div').outerHeight(true);
				modalH = 0;

				$modalChildDiv.find('> *').each(function () {
					modalH += $(this).outerHeight(true);
				});
				modalH += Number($modalChildDiv.css('padding-top').replace('px', ''));
				modalH += Number($modalChildDiv.css('padding-bottom').replace('px', ''));

				var half = 2;
				var max = 100;
				var percentage = 85;
				var pxAdjust = 100;
				var limitHeight = winH / max * percentage;
				var modalStyle = { 'height': modalH + 'px' };
				var isPossible = location.pathname.split('/')[2] === 'possible';

				// console.log('winH', winH);
				// console.log('modalH', modalH);
				// console.log('limitH', limitHeight);
				// console.log('scrollH', scrollH);
				// console.log('=======================');

				if (scrollH === 0 && cssua.ua.ie) {
					modalStyle = {
						'top': scrollH + (winH - modalChildDivH) / half + 'px',
						'bottom': 0,
						'margin': 'auto',
						'height': modalH + 'px'
					};
				} else if (scrollH > 0) {
					if (isPossible) {
						modalStyle = {
							'top': scrollH + (winH - modalChildDivH) / half + 'px',
							'bottom': 'auto'
						};
					} else {
						modalStyle = {
							'top': scrollH + (winH - modalChildDivH) / half + 'px',
							'bottom': 'auto',
							'height': modalH + 'px'
						};
					}
				}

				var iconHTML = $modal.find('.icon-close').length === 0 ? '<i class="' + modal + '-close icon icon-close"></i>' : '';

				if (typeof _slide !== 'undefined') {
					var num = _num;
					var targetLength = _nextTarget.length - 1;

					if (_slide.length > 0) {
						// グループで最後のモーダルじゃなければ追加
						if (num !== targetLength) iconHTML += '<i class="' + modal + '-slide-right icon icon-angle-right"></i>';
						// グループで最初のモーダルじゃなければ追加
						if (num !== 0) iconHTML += '<i class="' + modal + '-slide-left icon icon-angle-left"></i>';
					}
				}

				if (modalH > limitHeight) {
					if (scrollH > 0) {
						if (isPossible) {
							modalStyle = {
								'top': scrollH + (winH - limitHeight) / half + 'px',
								'bottom': 'auto'
							};
						} else {
							modalStyle = {
								'top': scrollH + (winH - limitHeight) / half + 'px',
								'bottom': 'auto',
								'height': winH - pxAdjust + 'px'
							};
						}
					} else if (isPossible || cssua.ua.ie) {
						modalStyle = {
							'bottom': 0,
							'margin': 'auto',
							'height': winH - pxAdjust + 'px'
						};
					} else {
						modalStyle = { 'height': winH - pxAdjust + 'px' };
					}

					$modal.css(modalStyle).find('> div').css({
						'overflow': 'hidden',
						'height': '100%'
					}).perfectScrollbar({
						useBothWheelAxes: true,
						suppressScrollX: true
					}).end().prepend(iconHTML);
				} else {
					$modal.prepend(iconHTML).css(modalStyle);
				}
			}
			function changeHTML(_name, _self, _num, _html, _slide) {
				var $parent = _self;

				$parent.attr('data-modal', _name);

				if (_slide.length > 0) $parent.attr('data-slideCount', _num);

				return $parent.html(_html);
			}
			function changeHistory(_historyId) {
				var id = _historyId;

				// if(history.replaceState) history.replaceState(id, null, '');
				if (history.pushState) history.pushState(id, null, '?modal=' + id);
			}
			function makeInnerSlide() {
				var $modal = $('.' + modal);
				var $innerSlide = $modal.find('.js-modal-innerSlide');

				if ($innerSlide.length > 0) {
					$innerSlide.owlCarousel({
						items: 1,
						margin: 20,
						nav: true,
						navText: ['<i class="icon icon-angle-left"></i>', '<i class="icon icon-angle-right"></i>'],
						loop: true,
						dots: false,
						autoplay: false,
						callbacks: true
					});
				}
			}
			function removeModal(_boolean, _fn) {
				var delay = 300;
				var $overlay = $('.' + overlay);
				var $modal = $('.' + modal);
				var timer;

				$modal.removeClass(visible);
				if (timer) clearTimeout(timer);

				// print対応class
				$(document.body).removeClass('is-modal');

				timer = setTimeout(function () {
					$modal.remove();

					if (_boolean) {
						if (timer) clearTimeout(timer);

						$overlay.removeClass(visible);
						timer = setTimeout(function () {
							$overlay.remove();
						}, delay);
					} else if (!_boolean && typeof _fn === 'function') {
						_fn();
					}
				}, delay);
			}
		},
		nextToggle: function nextToggle() {
			var _this = this;
			var toggle = document.querySelectorAll('.js-toggle');
			var toggleBlock = document.querySelectorAll('.js-toggleBlock');

			if (toggle.length > 0 && toggleBlock.length > 0) {
				if (location.hash) {
					_this.toggleControl($('#' + location.hash.substr(1)));
				}
				$('.js-toggle').on('click', function (event) {
					_this.toggleControl($(event.currentTarget));
					event.stopPropagation();
				});
				$('.prdDtl-cmsOnlyClass .ent_ct_title').on('click', function (event) {
					var $this = $(event.currentTarget);
					_this.toggleControl($this, $this.next('.ent_ct_body'));
					event.stopPropagation();
				});
			}
		},
		toggleControl: function toggleControl(_target, _nextTarget) {
			var _this = this;
			var visible = _this.state.visible;
			var $this = $(_target);
			var $next = _nextTarget || $this.next('.js-toggleBlock');
			var $plus = $this.find('.icon-plus');
			var $minus = $this.find('.icon-minus');
			var $caretDown = $this.find('.icon-caret-down');

			if ($next.length > 0) {
				if ($this.hasClass(visible)) {
					$this.removeClass(visible);
					$next.removeClass(visible);

					if ($minus.length > 0) $minus.removeClass('icon-minus').addClass('icon-plus');
					if ($caretDown.length > 0) $caretDown.removeClass('is-visible');
				} else {
					$this.addClass(visible);
					$next.addClass(visible);

					if ($plus.length > 0) $plus.removeClass('icon-plus').addClass('icon-minus');
					if ($caretDown.length > 0) $caretDown.addClass('is-visible');
				}
			}
			_this.adjustContentsHeight.fn();
			_this.adjustScrollNavigation();
			_this.setContainerHeightToNav();
		},
		easeScroll: function easeScroll() {
			// Smooth scroll.
			// $('body').on('click', 'a[rel="scroll"]', function(){
			$('a[rel="scroll"]').on(this.variable.click, function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var object = cssua.ua.mobile ? '.container' : 'html,body';
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				$(object).stop().animate({ scrollTop: pos - 100 }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash) {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;
						pos -= 100;

						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				});
			}
		},
		navScroll: function navScroll() {
			var _this = this;

			if (cssua.ua.desktop) {
				$(window).on({
					load: function load() {
						_this.setContainerHeightToNav();
						_this.adjustScrollNavigation();
					},
					scroll: function scroll() {
						_this.adjustScrollNavigation();
					},
					resize: function resize() {
						_this.setContainerHeightToNav();
						_this.adjustScrollNavigation();
					}
				});
			}
		},
		pagetop: function pagetop() {
			var $pagetop = $('.footer-pagetop');
			var $prdMenu = $('.prd-menu');
			var findPrdMenu = $prdMenu.length > 0;

			// Pagetop fixed.
			if ($pagetop.length > 0) {
				$(window).on('scroll', function (event) {
					var $this = $(event.currentTarget),
					    thisScroll = $this.scrollTop(),
					    footer = $('.footer'),
					    pagetopPos = $this.height() + $(footer).height() + thisScroll,
					    footerPos = $(footer).offset().top + $(footer).height();

					if (findPrdMenu && $prdMenu.hasClass('is-visible')) {
						$pagetop.addClass('is-menuopen');
					} else if ($pagetop.hasClass('is-menuopen')) {
						$pagetop.removeClass('is-menuopen');
					}

					if (thisScroll === 0) {
						$pagetop.removeClass('is-moved is-fixed').addClass('is-reset');
					} else {
						if (thisScroll > 0 && footerPos > pagetopPos) {
							$pagetop.removeClass('is-reset is-fixed').addClass('is-moved');
						}
						if (footerPos <= pagetopPos) {
							$pagetop.removeClass('is-reset is-moved').addClass('is-fixed');
						}
					}
				});
			}
		},
		topicPathRemoveTag: function topicPathRemoveTag() {
			var elTopicPath = document.querySelector('.topicPath');

			// パンくずタイトルから<br>タグを除去
			if (elTopicPath !== null) {
				var elAnchor = elTopicPath.querySelectorAll('.topicPath-li a');
				var elAnchorLen = elAnchor.length;

				for (var i = 0; i < elAnchorLen; i++) {
					var item = elAnchor[i].innerHTML;
					elAnchor[i].innerHTML = item.replace(/<br\s*[\/]?>/ig, '');
				}
			}
		},
		topicPathScroll: function topicPathScroll() {
			var elTopicPath = document.querySelector('.topicPath');
			var scrollFlag = false;
			var ulWidth = 0;
			var liWidth = 0;

			if (elTopicPath !== null) {
				addScrollbar();

				$(window).on('resize', function () {
					var timer = 300;
					var t;
					if (t !== false) clearTimeout(t);
					t = setTimeout(function () {
						if (typeof _function === 'function') {
							addScrollbar();
						}
					}, timer);
				});
			}

			function addScrollbar() {
				var elTopicPathUl = document.querySelector('.topicPath-ul');
				var elTopicPathLi = document.querySelectorAll('.topicPath-li');
				var liLength = elTopicPathLi.length;
				var whiteSpace = 156;

				elTopicPathUl.removeAttribute('style');
				ulWidth = elTopicPathUl.offsetWidth;
				liWidth = 0;

				for (var i = 0; i < liLength; i++) {
					liWidth += elTopicPathLi[i].offsetWidth;
				}

				if (liWidth > ulWidth) {
					elTopicPathUl.style.width = liWidth + whiteSpace + 'px';
					$(elTopicPath).perfectScrollbar({
						useBothWheelAxes: true,
						suppressScrollY: true
					});
					scrollFlag = true;
				} else {
					$(elTopicPath).perfectScrollbar('destroy');
					scrollFlag = false;
				}
				if (scrollFlag) $(elTopicPath).perfectScrollbar('update');
			}
		},
		tableWrapScroll: function tableWrapScroll() {
			var elWrap = document.querySelectorAll('.mod-tableWrap');
			if (elWrap.length > 0) {
				$(elWrap).perfectScrollbar({
					useBothWheelAxes: true,
					suppressScrollY: true
				});
			}
		},
		emptyObjectDelete: function emptyObjectDelete() {
			var emptyCheck = document.querySelectorAll('.js-empty-check');

			if (emptyCheck.length > 0) {
				for (var i = 0, emptyCheckLen = emptyCheck.length; i < emptyCheckLen; i++) {
					var obj = emptyCheck[i];
					var attr = obj.getAttribute('data-with');
					var objChildIsNull = obj.firstElementChild === null;
					var objText = $(obj).text().replace(/[\t\s\n]/g, '');

					if (objChildIsNull && objText.length === 0 && attr !== null) {
						$('.js-empty-delTarget[data-with="' + attr + '"]').remove();
					} else if (objChildIsNull && objText.length === 0) {
						$(obj).remove();
					}
				}
			}
		},
		currentUrlMatch: function currentUrlMatch() {
			var path = location.pathname;
			var anchor = document.getElementsByTagName('a');
			var replacePath = location.protocol + '//' + location.host;

			for (var i = 0, aLen = anchor.length; i < aLen; i++) {
				var href = anchor[i].href.replace(replacePath, '');

				if (path === href) anchor[i].classList.add('is-current');
			}
		},
		yearLinkGenerator: function yearLinkGenerator(_target) {
			var _this = this;
			var recently = $('#recentlyYear').text();
			var old = $('#oldYear').text();

			if (recently.length && old.length) {
				var html = '';
				var maxYear = 5;
				var category, i, decrease;
				var yearText = '';

				if (_target === 'news') {
					category = location.href.replace(/(.*)(\/(jp|en|sp)\/news\/\d+\/)(.*)/, '$2');

					for (i = recently; i >= old; i -= maxYear) {
						decrease = i - (maxYear - 1) >= old ? i - (maxYear - 1) : old;

						if (USHIO.lang() === 'jp') yearText = '年';

						html += '<li class="mod-yearList-li">\n\t\t\t\t\t\t<a href="' + (category + decrease) + '-' + i + '/">' + decrease + '\uFF5E' + (i + yearText) + '</a>\n\t\t\t\t\t\t</li>';
					}
					$('.mod-yearList-ul').append(html);

					_this.areaOuterClickInClose('.js-toggle', '.js-toggleBlock');
				}
			}
		},
		modInfoCarousel: function modInfoCarousel() {
			var $owl = $('.mod-info-newsList-ul');
			var itemColumn = 4;
			var bNav = true;

			$owl.on('initialized.owl.carousel', function (event) {
				var nav = document.querySelector('.mod-info-newsList-ul .owl-nav');
				var item = event.item;
				if (item.index === 0) {
					nav.querySelector('.owl-prev').style.display = 'none';
				}
				if (item.count <= itemColumn) {
					nav.style.display = 'none';
				}
			});
			$owl.on('translated.owl.carousel', function (event) {
				var nav = document.querySelector('.mod-info-newsList-ul .owl-nav');
				var prev = nav.querySelector('.owl-prev');
				var next = nav.querySelector('.owl-next');
				var item = event.item;
				if (item.index === 0) {
					prev.style.display = 'none';
				}
				if (item.count - item.index === itemColumn) {
					next.style.display = 'none';
				}
			});

			$owl.owlCarousel({
				items: itemColumn,
				margin: 20,
				nav: bNav,
				navText: ['<i class="icon icon-angle-left"></i>', '<i class="icon icon-angle-right"></i>'],
				loop: false,
				dots: false,
				autoplay: false,
				callbacks: true
			});
		},
		mainImageFontSize: function mainImageFontSize() {
			var $mainImage = $('.contents-mainImage-text');
			var power = 4;

			if ($mainImage.length > 0) {
				$mainImage.fitText(power, {
					minFontSize: '13px',
					maxFontSize: '18px'
				});
			}
		},
		print: function print() {
			return window.print();
		},
		googleMaps: function googleMaps() {
			var maps = google instanceof Object && typeof google.maps !== 'undefined';
			var link = document.querySelectorAll('.js-googleMaps-link');
			var address = document.querySelectorAll('.js-googleMaps-address');
			var lang;
			if (document.location.href.split('/')[3] === 'en') {
				lang = 'en';
			} else if (document.location.href.split('/')[3] === 'jp') {
				lang = 'jp';
			} else if (document.location.href.split('/')[3] === 'kr') {
				lang = 'kr';
			}
			if (maps && link.length && address.length) {
				var geocoder = new google.maps.Geocoder();
				var myOptions = {
					zoom: 17
				};
				address = address[0].textContent || address[0].innerText;

				geocoder.geocode({ address: address }, function (data, status) {
					if (status === google.maps.GeocoderStatus.OK) {
						var lat = data[0].geometry.location.lat();
						var lon = data[0].geometry.location.lng();

						var url = 'http://maps.google.com/maps?ll=' + lat + ',' + lon + '\n\t\t\t\t\t\t&q=' + address + '&z=17&t=m&hl=' + lang + '&gl=JP&mapclient=apiv3';
						var mapLink = document.querySelectorAll('.js-googleMaps-link')[0];
						mapLink.href = url;
					}
				});
			}
		},
		googleSearch: function googleSearch() {
			var cx;
			if (this.lang() === 'en') {
				cx = '006266489876715393143:uwqdzgfcs-o';
			} else if (this.lang() === 'jp') {
				cx = '006266489876715393143:llhvm7mq70q';
			} else if (this.lang() === 'kr') {
				cx = '006266489876715393143:ildat4zutbu';
			}
			var gcse = document.createElement('script');
			gcse.type = 'text/javascript';
			gcse.async = true;
			gcse.src = (document.location.protocol === 'https:' ? 'https:' : 'https:') + '//cse.google.com/cse.js?cx=' + cx;
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(gcse, s);
		},
		addWavebandLink: function addWavebandLink() {
			var waveband = document.querySelectorAll('.mod-waveband');

			if (waveband.length > 0) {
				$('tbody[data-waveband]').each(function () {
					var link = '/' + USHIO.lang() + '/products/keyword_details.html';
					var attr = this.getAttribute('data-waveband');
					$(this).find('a').each(function () {
						var num = Number(this.href.split('?wavelength=')[1]);
						if (isFinite(num)) {
							var query = (link.match(/\?/) || []).length > 0 ? '&' : '?';
							link += query + 'wavelength=' + num;
						}
					});
					link += '&key_label=' + encodeURI($('a[data-waveband="' + attr + '"]').text());
					$('a[data-waveband="' + attr + '"]').attr('href', link);
				});
			}
		},
		inquiryIncludeAjaxLoading: function inquiryIncludeAjaxLoading() {
			var inquiry = document.querySelectorAll('.mod-inquiry');
			var inquiryLen = inquiry.length;

			if (inquiryLen > 0) {
				for (var i = 0; i < inquiryLen; i++) {
					var array = inquiry[i].getAttribute('class').split(' ');
					var arrayLen = array.length;

					for (var j = 0; j < arrayLen; j++) {
						var key = array[j];
						if (key.match(/inc_contact/)) {
							includeAjax(key);
						}
					}
				}
			} else if (location.pathname.indexOf('/news/') !== -1) {
				if ($("div[class*='inc_contact']").length === 0) {
					$('#cms_contact_include').load('/' + USHIO.lang() + '/common/include/contact/inc_contact_corporate_commu.html');
				}
			}

			function includeAjax(_key) {
				console.log(_key);
				$.ajax({
					url: '/' + USHIO.lang() + '/common/include/contact/' + _key + '.html',
					dataType: 'html'
				}).done(function (data) {
					var item = $(data).filter(':first').html();
					$('.' + _key).html(item);
				}).fail(function () {
					console.log('error');
				});
			}
		},
		changeNewsCategoryText: function changeNewsCategoryText() {
			var $key = $('.cms_category_key');
			var $label = $('.cms_category_label');

			if ($key.length && $label.length) {
				$key.each(function () {
					var $this = $(this);
					$this.text($('.cms_category_label[id="cms_cc_' + $this.text() + '"]').text());
				});
			}
		},
		dataNotFound: function dataNotFound() {
			var content = document.querySelector('.container.cms_content_all');
			if (content === null) {
				location.href = '/' + USHIO.lang() + '/404.html?location=' + location.href;
			} else {
				return false;
			}
		},
		membersUriDecode: function membersUriDecode() {
			var link = document.querySelectorAll('a');
			var linkLen = link.length;

			for (var i = 0; i < linkLen; i++) {
				var item = link[i].href;
				if (typeof item !== 'undefined' && (item.match(/member\.ushio\.co\.jp/) || []).length > 0) {
					link[i].href = decodeURIComponent(item);
				}
			}
		},
		setGdprPopup: function setGdprPopup() {
			if ($.cookie('gdpr-popup') !== 'hidden') {
				$('.footer-gdpr').addClass('is-active');
			}
			$('.js-gdpr-agree').on('click', function () {
				$.cookie('gdpr-popup', 'hidden', { expires: 90, path: '/', secure: true });
				$('.footer-gdpr').removeClass('is-active');
			});
			$('.js-gdpr-noagree').on('click', function () {
				$.cookie('gdpr-popup', 'hidden', { expires: 90, path: '/', secure: true });
				$('.footer-gdpr').removeClass('is-active');
				if (USHIO.variable.pathname.indexOf('/jp/') === 0) {
					location.href = '/jp/cookie_policy.html';
				} else {
					location.href = '/en/cookie_policy.html';
				}
			});
		},


		idx: {
			mainVis: function mainVis() {
				$('.idx-mainVisual').owlCarousel({
					items: 1,
					loop: true,
					dots: true,
					dotsEach: true,
					autoplay: true,
					animateIn: 'fadeIn',
					animateOut: 'fadeOut'
				});
			},
			recommendSlide: function recommendSlide() {
				if ($('.idx-recommend-slider').find('a').length >= 4) {
					$('.idx-recommend-slider').owlCarousel({
						items: 3,
						loop: true,
						dots: true,
						dotsEach: true,
						nav: true,
						navText: ['<i class="icon icon-angle-left"></i>', '<i class="icon icon-angle-right"></i>'],
						autoplay: true
					});
				} else {
					$('.idx-recommend-slider').owlCarousel({
						items: 3,
						loop: false,
						dots: false,
						nav: false,
						draggable: false,
						autoplay: false
					});
				}
			},
			newsTab: function newsTab() {
				// JSONからニュース出力
				var getJSONNews = function getJSONNews(_region, _key) {
					return $.getJSON('/' + _region + '/ajax/news_list/' + _key);
				};

				$('.idx-newsList').each(function (idx, ele) {
					getJSONNews(USHIO.lang(), $(ele).attr('data-key')).then(function (data) {
						if (data.cms_content_news) {
							var newsListHTML = '';
							for (var i = 0; i < data.cms_content_news.length; i++) {
								newsListHTML += '<li class="__item"><div><time>' + data.cms_content_news[i].cms_news_date + '</time>' + ('<span class="__cat"><span>' + data.cms_content_news[i].cms_news_category_name + '</span></span>') + ('<a href="' + data.cms_content_news[i].cms_news_href.href + '" class="__title">' + data.cms_content_news[i].cms_news_title + '</a></div></li>');
							}
							$(ele).append(newsListHTML);
						} else {
							$(ele).append('<li>該当のニュースはありません。</li>');
						}
					});
				});

				// タブ切り替え
				var $tab = $('.idx-news-tab .__tab');
				var $content = $('.idx-newsList');
				$tab.on('click', function (e) {
					$tab.each(function (idx, ele) {
						$(ele).removeClass('is-active');
					});
					$(e.currentTarget).addClass('is-active');
					$content.each(function (idx, ele) {
						$(ele).removeClass('is-active');
					});

					var $switch = $('[data-key=' + $(e.currentTarget).attr('data-target') + ']');
					$switch.addClass('is-active');
				});
			}
		},
		prd: {
			menu: function menu() {
				var $menu = $('.prd-menu');
				var $menuWrapper = $menu.find('.prd-menu-wrapper');
				var $pagetop = $('.footer-pagetop');

				$('.prd-menu-btn').on('click', function (event) {
					var $this = $(event.currentTarget);
					var $window = $(window);
					var whiteSpace = 40;

					if ($window.scrollTop() === 0) {
						$menuWrapper.css({ 'height': $window.height() - whiteSpace + 'px' }).perfectScrollbar();
					} else {
						$menuWrapper.css({ 'height': $window.height() + 'px' }).perfectScrollbar();
					}
					$pagetop.toggleClass('is-menuopen');
					$menu.toggleClass('is-visible');
					$this.find('.icon-play').toggleClass('is-open');
				});
			},
			navScroll: function navScroll() {
				var $menu = $('.prd-menu');
				var $menuWrapper = $('.prd-menu-wrapper');
				var $footer = $('.footer');

				if (cssua.ua.desktop && $menu.length > 0) {
					$(window).on({
						load: function load() {
							$footer = $('.footer');
						},
						scroll: function scroll() {
							var $this = $(this),
							    thisHeight = $this.height(),
							    thisScroll = $this.scrollTop(),
							    pagetopPos = thisHeight + $footer.height() + thisScroll,
							    footerPos = $footer.offset().top + $footer.height(),
							    footerDiff = pagetopPos - footerPos,
							    menuHeight = thisHeight - footerDiff,
							    whiteSpace = 40;

							thisScroll === 0 ? $menu.removeClass('is-move') : $menu.addClass('is-move');

							if (footerDiff > 0) {
								$menuWrapper.css({ 'height': menuHeight + 'px' });
							} else {
								if (thisScroll === 0) {
									$menuWrapper.css({ 'height': thisHeight - whiteSpace + 'px' });
								} else {
									$menuWrapper.css({ 'height': thisHeight + 'px' });
								}
								return true;
							}
						}
					});
				}
			},
			hoverToHelp: function hoverToHelp() {
				$('body').on('click', '.prdList-fn-circleIcon', function (event) {
					var $this = $(event.currentTarget);
					if ($this.hasClass('is-hover')) {
						$this.removeClass('is-hover').find('.icon-cross').removeClass('icon-cross is-hover').addClass('icon-question');

						$('.prdList-fn-help').removeClass('is-visible');
					} else {
						$this.addClass('is-hover').find('.icon-question').removeClass('icon-question').addClass('icon-cross');

						$('.prdList-fn-help').addClass('is-visible');
					}
				});
			},
			fncCreateCategoryParameter: function fncCreateCategoryParameter() {
				$('.cms_parent_category_link').each(function () {
					var $this = $(this);
					var sCategoryParam = '?';
					$('.cms_subcategory_link[title="' + $this.attr('title') + '"]').each(function () {
						var sCategory = this.id.replace(/cms_pc_/, '');
						sCategoryParam += 'category=' + sCategory + '&';
					});
					$this.attr('href', '' + $this.attr('href') + sCategoryParam + 'key_label=' + encodeURIComponent($this.text()));
				});
			}
		},
		tech: {
			caseStudyFilter: function caseStudyFilter() {
				var listTransform = document.querySelectorAll('.js-listTransform');
				var listTransformLi = document.querySelectorAll('.js-listTransform-li');
				var listTransformLen = listTransform.length;

				// CMSoDから出力されるHTML構造ではchangeFilterItem()に対応出来ないのでDOM構造を強制変更
				if (listTransformLen) {
					getTabKeyAndSet();
					getListKeyAndSet();
				}

				function getTabKeyAndSet() {
					var tab = document.querySelectorAll('.js-filterTab');
					for (var i = 0, tabLen = tab.length; i < tabLen; i++) {
						var tabLi = tab[i].getElementsByTagName('li');

						for (var j = 0, tabLiLen = tabLi.length; j < tabLiLen; j++) {
							var key = tabLi[j].querySelector('[data-key]');
							var keyAttr;

							if (key !== null) {
								keyAttr = key.getAttribute('data-key');
								tabLi[j].setAttribute('data-key', keyAttr);
							}
						}
					}
					// アイテムが0ならタブを削除
					$('.js-filterTab').find('li').each(function (idx, ele) {
						var thisKey = $(ele).attr('data-key');
						if (thisKey !== 'all' && thisKey !== 'link') {
							var thisItemFlg = false;
							$('.cms_app_key').each(function (idx2, ele2) {
								if ($(ele2).text() === thisKey) {
									thisItemFlg = true;
								}
							});
							if (!thisItemFlg) {
								$(ele).remove();
							}
						}
					});
				}
				function getListKeyAndSet() {
					for (var i = 0, liLen = listTransformLi.length; i < liLen; i++) {
						var array = [];
						var li = listTransformLi[i];
						var key = li.querySelectorAll('.cms_app_key');
						li.classList.add('is-visible');

						for (var j = 0, keyLen = key.length; j < keyLen; j++) {
							array[j] = key[j].innerHTML;
						}
						li.setAttribute('data-key', String(array));
						$(li).find('.ent_cb_related_applications').remove();
					}
				}
			},
			listTransform: function listTransform() {
				var _this = this;
				var column = 3;
				var space = 20;
				var maxWidthPercent = 100;
				var time = 200;
				var timing = false;
				var $list = $('.js-listTransform');

				if ($list.length > 0) {
					$list.css({
						'margin-left': '-' + space + 'px'
					}).find('.js-listTransform-li').css({
						'padding-left': space + 'px',
						'width': maxWidthPercent + ' / ' + column + '%'
					});

					_this.startListTransform(column, space);

					$(window).on('resize', function () {
						if (timing !== false) clearTimeout(timing);

						timing = setTimeout(function () {
							if (typeof _function === 'function') {
								_this.startListTransform(column, space);
							}
						}, time);
					});
				}
			},
			startListTransform: function startListTransform(_column, _space) {
				var base = { w: 356, h: 220 };
				var $list = $('.js-listTransform');
				var $listLi = $('.js-listTransform-li');
				var listLiVisible = document.querySelectorAll('.js-listTransform-li.is-visible');
				var listLiHidden = document.querySelectorAll('.js-listTransform-li:not(.is-visible)');
				var listLiVisibleLength = listLiVisible.length;
				var listLiHiddenLength = listLiHidden.length;
				var listWidth = $list.width() / _column - _space;
				var listLiHeight = $listLi.height();
				var ulHeightMultiply = Math.ceil(listLiVisibleLength / _column);
				var ulHeight = listLiHeight * ulHeightMultiply + _space * ulHeightMultiply;
				var i, j, x, y, z, count;

				$listLi.find('a').height(parseInt(listWidth * (base.h / base.w), 10));

				for (i = 0; i < listLiVisibleLength; i++) {
					x = (listWidth + _space) * (i % _column) + 'px';
					y = (listLiHeight + _space) * (i / _column | 0) + 'px';
					z = 0;

					listLiVisible[i].style.webkitTransform = 'translate3d(' + x + ',' + y + ',' + z + ')';
					listLiVisible[i].style.transform = 'translate3d(' + x + ',' + y + ',' + z + ')';
				}
				count = i;
				for (j = 0; j < listLiHiddenLength; j++) {
					x = (listWidth + _space) * (count % _column) + 'px';
					y = (listLiHeight + _space) * (count / _column | 0) + 'px';
					z = 0;

					listLiHidden[j].style.webkitTransform = 'translate3d(' + x + ',' + y + ',' + z + ')';
					listLiHidden[j].style.transform = 'translate3d(' + x + ',' + y + ',' + z + ')';

					count++;
				}

				$list.height(ulHeight);

				USHIO.adjustContentsHeight.fn();
				USHIO.setContainerHeightToNav();
				USHIO.adjustScrollNavigation();
			}
		},

		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.emptyObjectDelete();
			_this.adjustContentsHeight.init();
			_this.globalMenuHoverAction();
			_this.headerMenu();
			_this.modalWindow();
			_this.nextToggle();
			_this.pagetop();
			_this.navScroll();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.footerBannerScrollingFollowUp();
			_this.topicPathRemoveTag();
			_this.topicPathScroll();
			_this.tableWrapScroll();
			_this.iconClickAnimation();
			_this.bytesToSize();
			_this.mainImageFontSize();
			_this.currentUrlMatch();
			_this.googleMaps();
			_this.googleSearch();
			_this.addWavebandLink();
			_this.inquiryIncludeAjaxLoading();
			_this.changeNewsCategoryText();
			_this.setGdprPopup();

			window.onload = function () {
				_this.membersUriDecode();
			};

			if (cssua.ua.mobile) _this.setStyleMobileDevice();

			// トップページ
			if (_this.location(/^\/(jp|en|kr)(|\/|\/index\.html)$/)) {
				_this.idx.mainVis();
				_this.idx.recommendSlide();
				_this.idx.newsTab();
			}
			// 企業情報トップ
			if (_this.location(/^\/(jp|en|kr|sp)\/company(|\/|\/index\.html)$/)) {
				_this.modInfoCarousel();
			}
			// ニュース年別
			if (_this.location(/^\/(jp|en)\/news\/(\d+)\/(\d+)\-(\d+)(|\/)$/)) {
				_this.yearLinkGenerator('news');
			}
			// 研究・技術情報
			if (_this.location(/^\/(jp|en|kr|sp)\/technology(|\/|\/index\.html)$/)) {
				_this.modInfoCarousel();
			}
			// IR
			if (_this.location(/^\/(jp|en|kr|sp)\/ir(|\/|\/index\.html)$/)) {
				_this.modInfoCarousel();
			}
			// 採用トップ
			if (_this.location(/^\/jp\/company\/recruit(|\/|\/index\.html)$/)) {
				_this.modInfoCarousel();
			}
			// 導入事例、大学研究室
			if (_this.location(/^\/(jp|en|kr|sp)\/technology\/(casestudy|univercity)(|\/|\/index\.html)$/)) {
				_this.tech.caseStudyFilter();
				_this.changeFilterItem();
				_this.tech.listTransform();
			}
			// ライトエッジ年別
			if (_this.location(/^\/jp\/technology\/lightedge\/year\/(\d+)\.html$/)) {
				_this.yearLinkGenerator('lightedge');
			}
			// 製品情報配下
			if (_this.location(/^\/(jp|en|kr|sp)\/products\/(index\.html)?/)) {
				_this.prd.menu();
				_this.prd.navScroll();
				_this.prd.hoverToHelp();
			}

			// IE8 PNG対応
			var IE8 = 8.0;
			if (cssua.ua.ie === IE8) {
				$('img[src]').each(function (event) {
					var $this = $(event.currentTarget);
					if (($this.attr('src').match(/\.png$/) || []).length > 0) {
						$this.css({
							'filter': 'progid:DXImageTransform.Microsoft.\n\t\t\t\t\t\t\tAlphaImageLoader(src="' + $this.attr('src') + '",\n\t\t\t\t\t\t\tsizingMethod="scale");'
						});
					}
				});
			}
		}
	};
	$.fn.fitText = function (kompressor, options) {
		var compressor = kompressor || 1,
		    settings = $.extend({
			'minFontSize': Number.NEGATIVE_INFINITY,
			'maxFontSize': Number.POSITIVE_INFINITY
		}, options);

		return this.each(function () {
			var $this = $(this);
			var resizer = function resizer() {
				var num = 10;
				var wi = $this.width();
				var co = compressor * num;
				var max = parseFloat(settings.maxFontSize);
				var min = parseFloat(settings.minFontSize);

				$this.css({
					'font-size': Math.max(Math.min(wi / co, max), min)
				});
			};
			resizer();
			$(window).on('resize.fittext orientationchange.fittext', resizer);
		});
	};

	window.USHIO || (window.USHIO = {});
	window.USHIO = USHIO || (USHIO = {});

	$(function () {
		USHIO.localDecision() ? USHIO.localLoading() : USHIO.loadDelayScript();
	});
})(jQuery);